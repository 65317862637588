// Generated by Framer (695b37f)

import * as React from "react";
import { motion, useOnVariantChange, useActiveVariantCallback, LayoutGroup, Image, transformTemplate, addFonts, withCSS, Container, getFonts, Text, withGeneratedLayoutId, useBreakpointVariants } from "framer";
import { cx } from "https://framer.com/m/framer/cx.js@^1.0.0";
import { useRandomID } from "https://framer.com/m/framer/randomID.js@^2.0.0";
import { addPropertyControls, ControlType } from "https://framer.com/m/framer/PropertyControls.js@^1.0.0";
import { useAddVariantProps } from "https://framer.com/m/framer/useAddVariantProps.js@^1.0.0";
import { useVariantState, CycleVariantState } from "https://framer.com/m/framer/useVariantState.js@^1.0.0";
import Navigation from "../canvasComponent/kulpKPG6y";
const NavigationFonts = getFonts(Navigation);
const Frame = withGeneratedLayoutId(motion.div);

const cycleOrder = ["WQLkyLRf1", "Cz0cLnvaR"];

const breakpoints = {"WQLkyLRf1": {"min": 1280}, "Cz0cLnvaR": {"max": 1279}}

const variantClassNames = {"WQLkyLRf1": "framer-v-72rtr7", "Cz0cLnvaR": "framer-v-10h7ie9"};

const humanReadableVariantMap = {"Desktop": "WQLkyLRf1", "Tablet": "Cz0cLnvaR"};

const transitions = {"default": {"duration": 0}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; variant?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ style, className, layoutId, width, height, variant: outerVariant = "WQLkyLRf1", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const initialVariant = useBreakpointVariants(variant, width, breakpoints);

const { variants, baseVariant, gestureVariant, classNames, transition, setVariant, setGestureState } = useVariantState({defaultVariant: "WQLkyLRf1", variant: initialVariant, transitions, variantClassNames, cycleOrder});

const variantProps = React.useMemo(() => ({"Cz0cLnvaR": {"WQLkyLRf1": {"data-framer-name": "Tablet"}, "oE8WU38am": {"variant": "JU6AsTSu2"}}}), []);

const addVariantProps = useAddVariantProps(baseVariant, gestureVariant, variantProps);

const defaultLayoutId = useRandomID();

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={initialVariant} animate={variants} className={cx("framer-KVOBC", classNames)} style={{"display": "contents"}} >
<Frame {...restProps} layoutIdKey="WQLkyLRf1" className={cx("framer-72rtr7", className)} style={{...style}} name="Desktop" data-framer-name="Desktop" transition={transition} ref={ref} {...addVariantProps("WQLkyLRf1")}><Container layoutIdKey="oE8WU38am" className="framer-14kigjx-container"  transition={transition} {...addVariantProps("oE8WU38am-container")}><Navigation width="100%" height="100%" layoutId="oE8WU38am" id="oE8WU38am" variant="R0_OmlxF1" style={{"width": "100%"}} transition={transition} {...addVariantProps("oE8WU38am")}/></Container><Text withExternalLayout={true} verticalAlignment="top" __fromCanvasComponent={true}  fonts={["Inter"]} center={true} layoutIdKey="zjxwgmOEq" className="framer-1gub6vt" rawHTML={"<div style='font-size: 0; line-height: 0; tab-size: 4; white-space: inherit; word-wrap: inherit'><div style='direction: ltr; font-size: 0'><span style=''>HALLO</span><br></div></div>"} transition={transition} {...addVariantProps("zjxwgmOEq")}/></Frame>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-KVOBC [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", ".framer-KVOBC .framer-72rtr7 { position: relative; overflow: hidden; width: 1280px; height: 1080px; background-color: #ffffff; }", ".framer-KVOBC .framer-14kigjx-container { position: fixed; height: auto; right: 0px; left: 0px; top: 0px; flex: none; z-index: 1; }", ".framer-KVOBC .framer-1gub6vt { position: absolute; overflow: visible; width: auto; height: auto; left: 50%; top: 50%; flex: none; white-space: pre; --framer-font-family: \"Inter\", sans-serif; --framer-text-color: #000000; --framer-font-size: 358px; --framer-letter-spacing: 0px; --framer-text-transform: none; --framer-text-decoration: none; --framer-line-height: 1.2em; --framer-text-alignment: start; --framer-link-text-decoration: underline; }", "@media (max-width: 1279px) { .framer-KVOBC .framer-72rtr7 { width: 600px; height: 1080px; }}"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1080
 * @framerIntrinsicWidth 1280
 * @framerCanvasComponentVariantDetails {"propertyName": "variant", "data": {"default": {"layout": ["fixed", "fixed"]}, "Cz0cLnvaR": {"layout": ["fixed", "fixed"]}}}
 * @framerResponsiveScreen
 */
const FrameraugiA20Il: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FrameraugiA20Il;

FrameraugiA20Il.displayName = "Home";

FrameraugiA20Il.defaultProps = {"width": 1280, "height": 1080};

addFonts(FrameraugiA20Il, [...NavigationFonts]);